<template>
    <div class="main" v-loading="loading">
        <div class="tab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in tabList" :key="index">
                    <div class="listData" v-if="list.list.length > 0">
                        <!-- 已发布 -->
                        <div class="every_data" v-for="(item, index) in list.list" :key="index">
                            <!-- <div class="every_data"> -->
                            <div class="flex_between" style="align-items: flex-start;">
                                <div style="display: flex;align-items: center;">
                                    <div class="data_title">
                                        {{ item.title }}
                                    </div>
                                    <div class="data_money">
                                        <span style="color: #D00000;" v-if="item.isFace == 1">面议</span>
                                        <span v-else>{{ item.salaryMin }}~{{ item.salaryMax }}K</span>
                                    </div>
                                </div>
                                <div class="data_mark1"
                                    v-if="item.upDown == 1 && item.recruitStatus != 1 && item.recruitStatus != 3">
                                    <span>已发布</span>
                                </div>
                                <div class="data_mark1"
                                    v-if="item.upDown == 2 && item.recruitStatus != 1 && item.recruitStatus != 3">
                                    <span>已下架</span>
                                </div>
                                <div class="data_mark1" v-if="item.recruitStatus == 1">
                                    <span>审核中</span>
                                </div>
                                <div class="data_mark1" v-if="item.recruitStatus == 3">
                                    <span>审核失败</span>
                                </div>

                            </div>
                            <div class="data_content ellipsis_2">
                                {{ item.description }}
                            </div>
                            <!-- <div class="data_content"
                                v-html="`<div>${item.description.replace(/[\r\n]/g, '<br>').replace(/(^\s)|(\s$)|\s/g, '&nbsp;')}</div>`">
                            </div> -->
                            <div class="flex_between" style="flex-wrap: wrap;">
                                <div style="display: flex;align-items: center;">
                                    <div class="data_company">
                                        {{ item.companyName }}
                                    </div>
                                    <div class="data_time">
                                        {{ item.releaseTime }}发布
                                    </div>
                                </div>
                                <!-- 已发布 -->
                                <div class="data_btn flex_between"
                                    v-if="item.upDown == 1 && item.recruitStatus != 1 && item.recruitStatus != 3">
                                    <!-- <div class="data_btn flex_between"> -->

                                    <div class="btns flex_between">
                                        <div class="btn1 btn" @click="editRent(item.id)">
                                            <!-- <div class="btn1 btn"> -->
                                            编辑
                                        </div>
                                        <div class="btn2 btn" @click="xiajia(item.id, 2)">
                                            <!-- <div class="btn2 btn"> -->
                                            下架
                                        </div>
                                        <div class="btn3 btn" @click="shanchu(item.id)">
                                            <!-- <div class="btn3 btn"> -->
                                            删除
                                        </div>
                                    </div>
                                </div>
                                <!-- 已下架 -->
                                <div class="data_btn flex_between"
                                    v-if="item.upDown == 2 && item.recruitStatus != 1 && item.recruitStatus != 3">
                                    <div class="btns flex_between">
                                        <div class="btn1 btn" @click="editRent(item.id)">
                                            编辑
                                        </div>
                                        <div class="btn2 btn" @click="xiajia(item.id, 1)">
                                            上架
                                        </div>
                                        <div class="btn3 btn" @click="shanchu(item.id)">
                                            删除
                                        </div>
                                    </div>
                                </div>
                                <!-- 审核中 -->
                                <div class="data_btn flex_between" v-if="item.recruitStatus == 1">
                                    <div class="btns flex_between">
                                        <div class="btn2 btn" @click="goAudit(1, item.id, item.failure)">
                                            审核详情
                                        </div>
                                    </div>
                                </div>
                                <!-- 审核失败 -->
                                <div class="data_btn flex_between" v-if="item.recruitStatus == 3">
                                    <div class="btns flex_between" @click="goAudit(0, item.id, item.failure)">
                                        <div class="btn2 btn">
                                            审核详情
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div v-else>
                        <el-empty :image-size="200"></el-empty>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="flag" style="position: relative;">
            <!-- 分页器 -->
            <div class="items"
                style="padding-bottom: 50px;margin-top: 50px;display: flex;justify-content: center;position: absolute;right: 0;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage4" :page-sizes="[10]" :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper" :total="list.count">
                </el-pagination>
            </div>
        </div>
        <div style="height: 200px;"></div>
        <div class="xiajia">
            <el-dialog top="160px" title="下架提醒" :visible.sync="showXiajia" width="700px" center>

                <div style="text-align:center;font-size: 18px;
font-family: PingFang SC-Medium, PingFang SC;
font-weight: 500;
color: #999999;">确定要下架该条招聘信息吗，下架后系统会为您保存三个月</div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="showXiajia = false">取消</el-button>
                    <el-button type="primary" @click="xiajiaYes">确定</el-button>
                </span>
            </el-dialog>
        </div>

        <div class="xiajia">
            <el-dialog top="160px" title="删除提醒" :visible.sync="showDelete" width="700px" center>
                <div style="text-align:center;font-size: 18px;
font-family: PingFang SC-Medium, PingFang SC;
font-weight: 500;
color: #999999;">确定要删除该条招聘信息吗</div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="showDelete = false">取消</el-button>
                    <el-button type="primary" @click="deleteYes">确定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="popup popup3">
            <el-dialog top="160px" :close-on-click-modal="false" title="岗位招聘信息发布" :visible.sync="fabuEdit" width="900px"
                center>
                <div class="form" v-show="step == 1">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/person/jbxx.png" alt="">
                            <img src="../../assets/person/gwxx.png" alt="">
                        </div>
                    </div>
                    <div class="form_con">
                        <el-form ref="form" :model="param" label-width="84px">
                            <div class="form_row">
                                <el-form-item label="岗位类别">
                                    <el-cascader placeholder="请选择" style="width:553px;" v-model="leibie"
                                        :props="{ children: 'child', value: 'id', label: 'className' }"
                                        :options="classList1" @change="handleChange"></el-cascader>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item label="岗位名称">
                                    <el-input style="width:553px;" v-model="param.title"></el-input>
                                </el-form-item>
                            </div>
                            <div style="border-bottom:1px solid #CCCCCC;margin:10px -30px 30px;"></div>
                            <div>
                                <el-form-item label="岗位描述" style="width: 100%;">

                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="工作内容" style="width: 100%;">
                                    <el-input placeholder="请输入" type="textarea" style="width: 553px;"
                                        v-model="param.desc"></el-input>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="岗位要求" style="width: 100%;">
                                    <el-input placeholder="请输入" type="textarea" style="width: 553px;"
                                        v-model="param.postRequire"></el-input>
                                </el-form-item>
                            </div>
                            <div style="border-bottom:1px solid #CCCCCC;margin:10px -30px 30px"></div>
                            <div class="form_row">
                                <el-form-item label="公司名称">
                                    <el-input placeholder="请输入" style="width:553px;" v-model="param.companyName"></el-input>
                                </el-form-item>
                            </div>
                            <!-- <div class="form_row">
                                <el-form-item label="福利待遇" style="width: 100%; ">
                                    <el-checkbox-group @change="changeFuli" v-model="fuli">
                                        <el-checkbox v-for="item in selectAll.recruit_welfare" :key="item.tagId"
                                            :label="item.tagId" name="type">{{ item.itemName }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </div> -->
                            <div>
                                <el-form-item label="福利待遇" style="width: 100%;">
                                    <el-input style="height: 100px;width: 553px;" type="textarea"
                                        placeholder="每项福利中间用/隔开，例如福利1/福利2/福利3" v-model="param.welfare"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row" style="padding:50px 0;">
                                <div style="width: 50%;margin:0 auto; cursor: pointer;" class="btnQueren"
                                    @click="nextStep1">
                                    下一步</div>
                            </div>
                        </el-form>
                    </div>
                </div>
                <div class="form" v-show="step == 2">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/person/jbxx.png" alt="">
                            <img src="../../assets/person/gwxx1.png" alt="">
                        </div>
                    </div>
                    <div class="form_con">
                        <el-form ref="form" :model="form" label-width="90px">
                            <div>
                                <el-form-item label="薪资范围">
                                    <el-radio-group v-model="param.isFace" style="width:40%;">

                                        <el-radio :label="0">
                                            <div style="width: 300px;height: 40px;font-size:18px;color:#333333;">
                                                <el-input type="number" style="width:104px" placeholder="最低薪资"
                                                    v-model="param.salaryMin"></el-input>&nbsp; K&nbsp; ~
                                                <el-input type="number" style="width:104px;" placeholder="最高薪资"
                                                    v-model="param.salaryMax"></el-input>&nbsp; K
                                            </div>
                                        </el-radio>
                                        <el-radio :label="1">面议</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                            <div class="form_row" style="display: flex;">
                                <el-form-item class="every_form" label="学历要求">
                                    <el-select style="width: 208px;" @change="changeXueli" class="form_inp"
                                        v-model="xueliName" placeholder="请选择">
                                        <el-option v-for="item in selectAll.warehouse_education" :key="item.tagId"
                                            :label="item.itemName" :value="item.tagId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="every_form" label="工作年限">
                                    <el-select @change="changeNianxian1" style="width: 100px;" v-model="param.workYearMin"
                                        placeholder="请选择">
                                        <el-option v-for="item in nianxianList" :key="item.type" :label="item.name"
                                            :value="item.type"></el-option>
                                    </el-select>&nbsp;~

                                    <el-select style="width: 100px;" v-model="param.workYearMax" placeholder="请选择">
                                        <el-option @change="changeNianxian2" v-for="item in nianxianList" :key="item.type"
                                            :label="item.name" :value="item.type"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>


                            <div class="form_row">
                                <el-form-item label="工作时间">
                                    <el-time-select placeholder="起始时间" v-model="param.workTimeBegin" :picker-options="{
                                        start: '08:30',
                                        step: '00:15',
                                        end: '18:30'
                                    }">
                                    </el-time-select>&nbsp;至
                                    <el-time-select placeholder="结束时间" v-model="param.workTimeEnd" :picker-options="{
                                        start: '08:30',
                                        step: '00:15',
                                        end: '18:30',
                                        minTime: param.workTimeBegin
                                    }">
                                    </el-time-select>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item class="every_form" label="工作地区">
                                    <el-cascader placeholder="请选择" style="width: 208px;" :props="propsRec"
                                        @change="changeFnRec"></el-cascader>
                                    <div v-if="show1" style="position:absolute;color:#606266;font-size:10px;left:10px;top:10px;background-color: #FFFFFF;line-height: 1.3em;font-size: 16px;width: 160px;display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;">
                                        {{ `${addressValue}/${cityValue}/${areaValue}` }}
                                    </div>
                                </el-form-item>
                                <el-form-item class="every_form" label="详细地址">
                                    <el-input placeholder="请输入" v-model="param.address" style="width: 208px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item label="联系人" style="width: 50%;">
                                    <el-input placeholder="请输入" v-model="param.contactName"
                                        style="width: 208px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row">
                                <el-form-item label="联系电话" style="width: 50%;">
                                    <el-input placeholder="请输入" autocomplete="off"
                                        oninput="value=value.replace(/[^0-9]/g,'')" maxlength="11"
                                        v-model="param.contactWay" style="width: 208px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form_row" style="padding:50px 0;display: flex;">
                                <div style="width: 40%;margin:0 auto;cursor: pointer;" class="btnQueren" @click="step = 1">
                                    上一步</div>
                                <div style="width: 40%;margin:0 auto;cursor: pointer;" class="btnQueren" @click="nextStep3">
                                    确认发布</div>
                            </div>
                        </el-form>
                    </div>
                </div>
            </el-dialog>
        </div>
        <div class="shenhe">
            <el-dialog top="160px" title="人才招聘信息发布" :visible.sync="shenhe" width="700px" center>
                <div class="shenheimg">
                    <img v-if="type == 1" src="@/assets/person/shenhe.png" alt="">
                    <img v-if="type == 0" src="@/assets/person/shenheno.png" alt="">
                </div>
                <div class="tishi">
                    <div v-if="type == 1"> 后台审核中，请耐心等待</div>
                    <div v-if="type == 1">我们会在24小时内给您反馈</div>
                    <div v-if="type == 0">审核失败，请更正后重新提交</div>
                </div>
                <div class="reason" v-if="type == 0">
                    {{ reason }}
                </div>

                <div class="buttn" @click="shenhe = false" v-if="type == 1">
                    我知道了
                </div>
                <div class="buttn" v-if="type == 0" @click="xiugaiShenhe">
                    去修改
                </div>
            </el-dialog>
        </div>


    </div>
</template>
<script>


export default {
    data() {
        let that = this;
        return {
            show1: false,
            propsRec: {
                lazy: true,
                label: 'name', value: 'id',
                lazyLoad: async (node, resolve) => {
                    console.log(node, 9999999999)
                    const { level } = node;
                    if (level >= 1) {
                        if (level == 3) {
                            return
                        }
                        let data = await this.getArea(node.value) //[{ id: 1, name: 222 }]//
                        let res = data.map(i => {
                            i.leaf = level >= 2
                            return i
                        })
                        resolve(res);
                    } else {

                        resolve(this.addressList);
                    }
                }
            },
            loading: false,
            shenheId: '',
            type: 1,
            reason: '',
            shenhe: false,
            addressList: '',
            cityList: '',
            areasList: '',
            addressValue: '',
            cityValue: '',
            areaValue: '',
            value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
            fuli: [],
            workYearMin: '',
            workYearMax: '',
            classList1: [],
            classList2: [],
            classList: [],
            classify1: '',
            classify2: '',
            fabuEdit: false,
            activeName: 'first',
            step: 1,

            value: '',
            tabList: [
                {
                    label: "全部",
                    name: "first",
                },
                {
                    label: "已发布",
                    name: "second",
                },
                {
                    label: "已下架",
                    name: "third",
                },
                {
                    label: "审核中",
                    name: "fourth",
                },
                {
                    label: "审核失败",
                    name: "fifth",
                }
            ],
            params: {
                type: 3,
                status: 0,
                upDown: 0,
                // uid: '',
                page: 1,
                viewType: 1
            },
            list: {
                list: []
            },
            form: {
                id: '',
                opType: 3,
                upDown: ''
            },
            showXiajia: false,
            showDelete: false,
            deleteParam: {
                id: '',
                opType: 4,
            },
            currentPage4: 1,
            detailParam: {
                type: 3,
                viewType: 1,
                id: ''
            },
            detail: {},
            selectAll: {},
            checkedList: [],
            education: '',
            showBegin: false,
            value1: "",
            showEnd: false,
            showReturn: false,
            showDelete: false,
            money_confirm: false, //	新增范围确定
            tielu: false, //是否有铁路专用线
            shangxian: false, //是否上线兑货宝
            labelIndex: -1,
            dateStart: '',
            datePlan: '',
            categoryIndex: -1, //存放品类
            categoryList: [],
            nianxianIndex: -1,
            nianxianName: '',
            nianxianList: [
                {
                    name: "1年",
                    type: 1
                },
                {
                    name: "2年",
                    type: 2
                },
                {
                    name: "3年",
                    type: 3
                },
                {
                    name: "4年",
                    type: 4
                },
                {
                    name: "5年",
                    type: 5
                },
                {
                    name: "6年",
                    type: 6
                },
                {
                    name: "7年",
                    type: 7
                },
                {
                    name: "8年",
                    type: 8
                },
                {
                    name: "9年",
                    type: 9
                },
                {
                    name: "10年",
                    type: 10
                }


            ],
            xueliIndex: 0,
            xueliName: '',
            xueliList: [],
            step: 1, //当前步骤
            //	薪资范围信息定义
            xinzi: '',
            //	岗位信息
            step1: {
                title: '', //	岗位类别
                classinfo: {
                    className: ''
                }, //岗位信息
                classId: null, //	类别id
                desc: '', //	岗位描述
                companyName: '', //	公司名称
                welfare: [], //	福利待遇
            },
            step2: {
                salaryMin: "", //	薪资最小值
                salaryMax: "", //	薪资最大值
                mianyi: false, //	是否面议
            },
            stepListTab: [{
                name: '基本信息',
                id: 1,
                checkedImg: '/static/rentout/step1.png',
                unCheckedImg: '/static/rentout/step1.png',

            }, {
                name: '岗位要求',
                id: 2,
                checkedImg: '/static/rentout/step2.png',
                unCheckedImg: '/static/rentout/step2n.png',
            }], //支持字体颜色，线条等变量, //步骤列表
            fuliList: [],
            addressIndex: 0, //仓库地址
            addressList: [],
            oldpProvinceDataList: [

            ],
            newProvinceDataList: [
                [],
                [],
                []
            ],
            arr1: [],
            arr2: [],
            multiIndex: [0, 0, 0],
            checkedList: [], //选中的数据
            param: {
                opType: 1,
                title: '', //岗位标题
                classId: '', //类别id
                desc: '', //岗位描述
                postRequire: '',
                companyName: '', //公司名称
                welfare: '', //福利待遇
                salaryMin: '', //薪资最小值
                salaryMax: '', //薪资最大值
                education: '', //学历 枚举或者从tag中取
                workYearMin: '', //工作年限最小值 字符串
                workYearMax: '', //工作年限最大值 字符串
                workTimeBegin: '', //上班时间 开始
                workTimeEnd: '', //下班时间 结束
                provinceId: '', //
                cityId: '', //
                regionId: '', //工作地区
                address: '', //详细地址
                contactName: '', //联系人姓名
                contactWay: '', //联系方式
                isFace: 0, //是否面议
            },
            addressSelect: '',
            selectAll: {},
            options: {},
            detailParam: {
                type: 3,
                viewType: 1,
                id: ''
            },
            classList: [],
            leibie: [],
            flag: true,

        };
    },

    mounted() {

    },
    created() {
        this.rentList()
        this.getSelect()
        this.get_recruit_class()
        this.getAreaSheng()
    },
    methods: {
        nextStep1() {
            if (this.param.classId == '') {
                this.$message({
                    message: '请选择岗位类别',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.title == '') {
                this.$message({
                    message: '请输入岗位名称',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.desc == '') {
                this.$message({
                    message: '请输入工作内容',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.postRequire == '') {
                this.$message({
                    message: '请输入岗位要求',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.companyName == '') {
                this.$message({
                    message: '请输入公司名称',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            this.step = 2
        },
        changeFnRec(e) {
            console.log(e);
            this.param.provinceId = e[0]
            this.param.cityId = e[1]
            this.param.regionId = e[2]
            this.show1 = false
        },
        handleChange(e) {
            console.log(e);
            this.param.classId = e[1]
        },
        goAudit(type, id, reason) {
            this.type = type
            this.reason = reason
            this.shenheId = id
            this.shenhe = true

        },
        xiugaiShenhe() {
            this.editRent(this.shenheId)
            this.get_recruit_class()
        },
        async nextStep3() {
            console.log(this.param.salaryMax);
            if (this.param.isFace == 0) {
                if (this.param.salaryMin == '') {
                    this.$message({
                        message: '请输入最低薪资',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                if (this.param.salaryMax == '') {
                    this.$message({
                        message: '请输入最高薪资',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }

                if (this.param.salaryMax <= this.param.salaryMin) {
                    this.$message({
                        message: '最高薪资必须大于最低薪资',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }

            }


            if (this.param.education == '') {
                this.$message({
                    message: '请选择学历',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.workYearMin == '') {
                this.$message({
                    message: '请选择最小工作年限',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.workYearMax == '') {
                this.$message({
                    message: '请选择最大工作年限',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.workYearMax <= this.param.workYearMin) {
                this.$message({
                    message: '最大工作年限必须大于最小工作年限',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.workTimeBegin == '') {
                this.$message({
                    message: '请选择工作开始时间',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.workTimeEnd == '') {
                this.$message({
                    message: '请选择工作结束时间',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.regionId == '') {
                this.$message({
                    message: '请选择工作地区',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.address == '') {
                this.$message({
                    message: '请输入详细地址',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.contactName == '') {
                this.$message({
                    message: '请输入联系人姓名',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.contactWay == '') {
                this.$message({
                    message: '请输入联系电话',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.contactWay.length != 11) {
                this.$message({
                    message: '联系电话格式不正确',
                    type: 'warning',
                    offset: 100
                });
                return
            }

            let data = await this.$api.base.release_recruit(this.param)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: "提交成功",
                    type: 'success',
                    offset: 100
                });
                this.fabuEdit = false
                this.type = 1
                this.shenhe = true
                this.rentList()
                nav.classList.remove("fixedNav");

            }
            if (data.code == -9999) {
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });
            }
            // 
        },
        async changeSheng(e) {
            this.param.provinceId = e
            this.cityList = await this.getArea(e)

        },
        async changeShi(e) {
            this.param.cityId = e
            this.areasList = await this.getArea(e)

        },
        async changeQu(e) {
            this.param.regionId = e
            // this.param.county = e


        },
        changeNianxian1(e) {
            console.log('picker发送选择改变，携带值为', e)
            // this.nianxianName = this.nianxianList[0][e.detail.value[0]].name + "~" + this.nianxianList[1][e.detail
            //     .value[1]
            // ].name
            this.param.workYearMin = e

        },
        selectTime(e) {
            console.log(e);
        },
        changeNianxian2(e) {
            console.log('picker发送选择改变，携带值为', e)
            // this.nianxianName = this.nianxianList[0][e.detail.value[0]].name + "~" + this.nianxianList[1][e.detail
            //     .value[1]
            // ].name
            this.param.workYearMax = e

        },
        changeXueli(e) {
            console.log('picker发送选择改变，携带值为', e)

            // if (e.detail.value == NaN) {
            //     console.log(1111);
            //     this.xueliName = this.selectAll.warehouse_education[0].itemName
            //     return
            // }
            // this.xueliName = this.selectAll.warehouse_education[e.detail.value].itemName
            this.param.education = e
        },
        changeFuli(e) {
            console.log(e);
            this.param.welfare = e.join(",")
        },
        changeClass(e) {
            console.log(e);
            for (let i = 0; i < this.classList1.length; i++) {
                if (this.classList1[i].id == e) {
                    this.classList2 = this.classList1[i].child
                }

            }
        },
        changeClass1(e) {
            this.param.classId = e
        },
        async get_recruit_class() {
            let data = await this.$api.base.get_recruit_class()
            console.log(data);
            this.classList = data.list
            this.classList1 = data.list

        },

        async rentDetail() {
            this.show1 = true
            this.loading = true
            let data = await this.$api.base.rentDetail(this.detailParam)
            console.log(data);
            if (data.code == -9999) {
                this.loading = false
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });

                return
            }
            this.param.opType = 2
            this.param.id = this.detailParam.id
            this.param.title = data.info.title
            this.param.classId = data.info.classId
            // this.leibie = 
            let one = ''
            for (var i = 0; i < this.classList.length; i++) {
                for (var j = 0; j < this.classList[i].child.length; j++) {
                    if (this.classList[i].child[j].id == data.info.classId) {
                        one = this.classList[i].id
                        // this.step1.classinfo.className = this.classList[i].child[j].className
                        break
                    }
                }
            }
            this.leibie[0] = one
            this.leibie[1] = data.info.classId
            console.log(this.leibie);
            // 
            this.param.desc = data.info.description
            this.param.postRequire = data.info.postRequire
            this.param.companyName = data.info.companyName
            this.param.welfare = data.info.welfare
            // this.fuli = data.info.welfare.split(",")
            // let arr2 = []
            // // console.log(arr1);
            // // console.log(this.selectAll);
            // for (var i = 0; i < this.selectAll.recruit_welfare.length; i++) {
            //     for (var j = 0; j < arr1.length; j++) {
            //         if (this.selectAll.recruit_welfare[i].tagId == arr1[j]) {
            //             arr2[arr2.length] = this.selectAll.recruit_welfare[i]
            //         }
            //     }
            // }
            // this.checkedList = arr2
            this.param.isFace = data.info.isFace
            if (data.info.isFace == 0) {
                this.param.salaryMin = data.info.salaryMin
                this.param.salaryMax = data.info.salaryMax
                this.step2.mianyi = false
                this.xinzi = data.info.salaryMin + '~' + data.info.salaryMax
            } else {
                this.step2.mianyi = true
                this.xinzi = "面议"
            }
            this.param.education = data.info.education
            // 
            for (var i = 0; i < this.selectAll.warehouse_education.length; i++) {
                if (this.selectAll.warehouse_education[i].tagId == data.info.education) {
                    this.xueliName = this.selectAll.warehouse_education[i].itemName
                }
            }
            this.param.workYearMin = data.info.workYearMin
            this.workYearMin = data.info.workYearMin + "年"
            this.param.workYearMax = data.info.workYearMax
            this.workYearMax = data.info.workYearMax + "年"
            this.nianxianName = data.info.workYearMin + '~' + data.info.workYearMax
            this.param.workTimeBegin = data.info.workTimeBegin
            this.param.workTimeEnd = data.info.workTimeEnd
            this.param.provinceId = data.info.provinceId
            this.param.cityId = data.info.cityId
            this.param.regionId = data.info.regionId
            this.param.address = data.info.address
            this.param.contactName = data.info.contactName
            this.param.contactWay = data.info.contactWay
            this.addressSelect = data.info.oneName + data.info.twoName + data.info.threeName

            this.addressValue = data.info.oneName
            this.cityValue = data.info.twoName
            this.areaValue = data.info.threeName
            this.loading = false
            this.fabuEdit = true
            nav.classList.add("fixedNav");
        },
        // 获取第一层省市区
        async getAreaSheng() {
            let data = await this.$api.base.getArea()
            // console.log(data);
            this.addressList = data.list
        },
        // 根据父级id获取市区
        // async getArea(id) {
        //     let data = await this.$api.base.getArea({
        //         areaPid: id
        //     })
        //     return data.list

        // },










        async xiajiaYes() {
            let data = await this.$api.base.release_recruit(this.form)
            console.log(data);
            this.showXiajia = false
            this.rentList()
        },
        async xiajia(id, status) {
            this.form.id = id
            this.form.upDown = status
            if (status == 2) {
                this.showXiajia = true
                return
            }
            if (status == 1) {
                let data = await this.$api.base.release_recruit(this.form)
                console.log(data);
                if (data == null) {
                    this.$message({
                        message: "上架成功",
                        type: 'success',
                        offset: 100
                    });
                }
                this.rentList()
            }
        },
        async deleteYes() {
            let data = await this.$api.base.release_recruit(this.deleteParam)
            console.log(data);
            this.showDelete = false
            this.rentList()
        },
        shanchu(id) {
            this.deleteParam.id = id
            this.showDelete = true
        },
        handleClose() {
            this.fabuEdit = false
            nav.classList.remove("fixedNav");
        },
        editRent(id) {
            this.step = 1
            this.detailParam.id = id
            this.rentDetail()


        },
        // async rentDetail() {

        //     let data = await this.$api.base.rentDetail(this.detailParam)
        //     console.log(data);
        //     this.detail = data.info
        //     // this.detail.banner = this.detail.banner.split(",")
        //     // this.detail.tags = this.detail.tags.split(",")
        //     this.detail.releaseTime = this.$util.formatTime(this.detail.releaseTime, "YY-M-D")
        //     let arr1 = data.info.welfare.split(",")
        //     let arr2 = [];
        //     console.log(this.selectAll);
        //     for (var i = 0; i < this.selectAll.recruit_welfare.length; i++) {
        //         for (var j = 0; j < arr1.length; j++) {
        //             if (this.selectAll.recruit_welfare[i].tagId == arr1[j]) {
        //                 arr2[arr2.length] = this.selectAll.recruit_welfare[i]
        //             }
        //         }
        //     }
        //     for (var i = 0; i < this.selectAll.warehouse_education.length; i++) {
        //         if (this.selectAll.warehouse_education[i].tagId == this.detail.education) {
        //             this.education = this.selectAll.warehouse_education[i].itemName
        //         }
        //     }
        //     this.checkedList = arr2
        //     this.fabuEdit = true

        // },
        // 根据父级id获取市区
        async getArea(id) {
            let data = await this.$api.base.getArea({
                areaPid: id
            })
            // console.log(data.list);
            return data.list
            // this.addressList = data.list

        },
        // 获取初始化选项列表
        async getSelect() {
            let data = await this.$api.base.getSelect()
            console.log(data);
            this.selectAll = data.info

        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.params.page = val
            this.rentList()
        },
        async rentList() {
            let {
                params
            } = this;
            this.loading = true
            let newList = await this.$api.base.rentList(params);
            console.log(newList);
            for (let i = 0; i < newList.list.length; i++) {
                // const element = array[i];
                newList.list[i].releaseTime = this.$util.formatTime(newList.list[i].releaseTime, "YY-M-D")

            }
            this.list = newList
            this.loading = false
        },
        handleClick(tab, event) {
            this.flag = false
            setTimeout(() => {
                this.currentPage4 = 1
                this.flag = true
            }, 500)


            this.activeName = tab.name
            console.log(tab.index, tab.name);
            this.params.page = 1

            if (tab.index == 0) {
                this.params.status = 0
                this.params.upDown = 0
            } else if (tab.index == 1) {
                this.params.status = 2
                this.params.upDown = 1
            } else if (tab.index == 2) {
                this.params.status = 0
                this.params.upDown = 2
            } else if (tab.index == 3) {
                this.params.status = 1
                this.params.upDown = 0
            } else if (tab.index == 4) {
                this.params.status = 3
                this.params.upDown = 0
            }

            this.rentList()
        },

    },
};
</script>

<style scoped lang="less">
.ellipsis_2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

/deep/ .el-pagination .btn-next {
    // width: 20px !important;
    background: center center no-repeat #00B079;

}

/deep/ .el-pagination .btn-prev {
    background: center center no-repeat #00B079;


}

/deep/ .el-dialog {

    border-radius: 10px !important;
}

/deep/ .el-pager li.active {
    color: #00B079;
    cursor: default;
}

/deep/ .el-pager li:hover {
    color: #00B079;
}

.shenhe {
    .shenheimg {
        text-align: center;

        img {
            width: 200px;
            height: 200px;
        }
    }

    .tishi {
        div {
            height: 34px;
            line-height: 34px;
            text-align: center;
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #CCCCCC;
            line-height: 28px;
        }
    }

    .reason {

        width: 60%;
        margin: 0 auto;
        padding: 30px 50px;
        // height: 156px;
        background: #F0F0F0;
        border-radius: 10px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
    }

    .buttn {
        width: 260px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #00B079;
        border-radius: 10px 10px 10px 10px;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin: 50px auto;
        cursor: pointer;
    }
}

/deep/ .el-textarea__inner:focus {
    outline: 0;
    border-color: #00B079;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #00B079;
    background: #00B079;
}

/deep/ .el-radio__inner:hover {
    border-color: #00B079;
    // background: #00B079
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #00B079;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #00B079;
    border-color: #00B079;
}

/deep/ .el-checkbox__inner:hover {
    border-color: #00B079;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #00B079;
}

.btnQueren {

    height: 48px;
    line-height: 48px;
    text-align: center;
    background: #00B079;
    border-radius: 10px 10px 10px 10px;

    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
}

.popup {
    .form {
        .stepList {
            .step {
                display: flex;
                justify-content: center;

                img {
                    width: 220px;
                }
            }
        }

        .form_con {
            margin-top: 50px;

            .form_row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                height: 42px;
                margin-bottom: 30px;

                .every_form {
                    // display: flex;
                    // align-items: center;
                    // flex-wrap: wrap;
                    // justify-content: right;
                    width: 50%;


                    .form_inp {
                        width: 70%;
                        cursor: pointer;
                        font-size: 16px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #00B079;

                        span {
                            cursor: pointer;
                            font-size: 16px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #00B079;
                        }


                    }

                    span {
                        margin-left: 10px;
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }


                }


            }
        }
    }
}

.form_con {
    margin-top: 50px;
    padding: 0 100px;

    .form_row {

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        height: 42px;
        margin-bottom: 30px;


        .every_form {
            // display: flex;
            // align-items: center;
            // flex-wrap: wrap;
            // justify-content: right;

            width: 50%;


            .form_inp {
                width: 70%;
                cursor: pointer;
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #00B079;

                span {
                    cursor: pointer;
                    font-size: 16px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #00B079;
                }


            }

            span {
                margin-left: 10px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }


        }


    }
}

/deep/ .el-tabs__item.is-active {
    color: #00B079;
}

/deep/ .el-tabs__active-bar {

    background-color: #00B079;

}

/deep/ .el-tabs__item:hover {
    color: #00B079;
}

/deep/ .el-tabs__item {
    padding: 0px 24%;

}

/deep/ .el-tabs__item {
    color: #999999 !important;
    font-size: 18px !important;
}

.main {
    /deep/ .el-loading-spinner .path {

        stroke: #00B079 !important;
    }

    .flex_center_a {
        display: flex;
        align-items: center;

    }

    .flex_center_a_w {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .flex_between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .listData {
        // padding: 110px 30px;

        .every_data {
            background: #FFFFFF;
            // padding: 30px;
            border-bottom: 1px solid #CCCCCC;

            padding: 30px 0;
            // border-radius: 20px;
            margin-bottom: 20px;

            .data_mark1 {
                span {
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                }
            }

            .data_title {
                font-size: 18px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-right: 30px;
            }

            .data_money {
                span {
                    font-size: 20px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #00B079;
                }

            }

            .data_content {
                margin: 20px 0 30px 0;
                font-size: 15px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 29px;

            }

            .data_company,
            .data_time {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #CCCCCC;
                margin-bottom: 10px;
            }

            .data_company {
                // width: 59%;
                // background-color: #09B37E;
                margin-right: 50px;

            }

            .data_time {
                // width: 40%;
                // background-color: #09B37E;
                // span-align: right;
            }

        }

        .data_btn {



            // .data_mark2 {
            //     font-size: 28px;
            //     color: #999999;
            // }

            // .data_mark3 {
            //     font-size: 28px;
            //     color: #09B37E;
            // }

            // .data_mark4 {
            //     font-size: 28px;
            //     color: #D00000;
            // }

            .btns {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: bold;

                // color: #FFFFFF;
                .btn {
                    cursor: pointer;
                    padding: 4px 20px;
                    border-radius: 100px;
                    margin-left: 10px;
                }

                .btn1 {
                    background: #09B37E;
                    color: #FFFFFF;
                    border: 1px solid #09B37E;
                }

                .btn2 {
                    border: 1px solid #09B37E;
                    color: #09B37E;
                }

                .btn3 {
                    border: 1px solid #D00000;
                    color: #D00000;
                }
            }
        }
    }


}

/deep/ .el-dialog__title {

    font-weight: 600;
}

.tab {
    /deep/ .el-tabs__nav-wrap {

        border-bottom: 1px solid #CCCCCC;
        padding-left: 20px;
    }
}

.popup {
    /deep/ .el-textarea__inner {
        height: 100px !important;
    }

    /deep/ .el-form-item__label {

        font-size: 18px !important;
        font-family: PingFang SC-Regular, PingFang SC !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }

    /deep/ .el-radio__label {
        font-size: 18px !important;
        font-family: PingFang SC-Regular, PingFang SC !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }
}

.popup3 {
    /deep/ .el-radio {

        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    /deep/ .el-input__inner {
        border-color: #DCDFE6 !important;
    }

    /deep/ .el-textarea__inner {
        border-color: #DCDFE6 !important;
    }

    .form_row {
        display: flex;
    }

}

/deep/ .el-textarea__inner {

    // font-size: 14px;

    font-family: auto;
}
</style>
